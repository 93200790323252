import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import Line from 'components/Line'
import { COLOR, FONT_SIZE } from 'Theme'
import { Flex } from 'components/Layout'

const StyledLine = styled(Line)`
  background-color: ${COLOR.WHITE_75};
`

const Text = styled('span')`
  font-size: ${FONT_SIZE.S};
  text-transform: uppercase;
  white-space: nowrap;
  margin: 0 ${rem(12)};
`

const TextLine = ({ text }) => (
  <Flex alignItems="center">
    <StyledLine />
    <Text>{text}</Text>
    <StyledLine />
  </Flex>
)

TextLine.propTypes = {
  text: PropTypes.string.isRequired,
}

export default TextLine
