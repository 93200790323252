import SuccessMessage from 'components/SuccessMessage'
import styled from 'styled-components'
import { cover } from 'polished'
import { COLOR, GRADIENT } from 'Theme'

export default styled(SuccessMessage)`
  ${cover()}

  margin: 0;

  background-color: ${COLOR.BLACK};
  background-image: linear-gradient(${GRADIENT.COURSE_CARD});

  transition: opacity 0.4s ease-out;

  ${({ $isVisible }) =>
    !$isVisible &&
    `
      pointer-events: none;
      user-select: none;

      opacity: 0;
    `}
`
