import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SPACE } from 'Theme'
import Disclosure from 'components/Disclosure'

const StyledFAQ = styled('div')`
  > * + * {
    margin-top: ${SPACE.L};
  }
`

const FAQ = ({ items }) => {
  const [openQuestionIndex, setOpenQuestionIndex] = React.useState(-1)
  const togglePanel = (questionIndex) => () =>
    setOpenQuestionIndex((prevOpen) =>
      prevOpen === questionIndex ? -1 : questionIndex
    )

  return (
    <StyledFAQ>
      {items.map(({ question, answer }, index) => (
        <Disclosure
          summary={question}
          content={answer}
          key={index}
          isOpen={openQuestionIndex === index}
          onChange={togglePanel(index)}
        />
      ))}
    </StyledFAQ>
  )
}

FAQ.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.node.isRequired,
    }).isRequired
  ).isRequired,
}

export default FAQ
