import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import {
  Disclosure as Wrapper,
  DisclosureButton,
  DisclosurePanel,
} from '@reach/disclosure'
import { COLOR, FONT_SIZE, FONT_WEIGHT, RADIUS, SPACE } from 'Theme'

const reveal = keyframes`
  from {
    transform: translateY(-3rem);
    opacity: 0;
  }
`

const Button = styled(DisclosureButton)`
  display: flex;
  align-items: baseline;

  font-size: ${FONT_SIZE.XL};
  font-weight: ${FONT_WEIGHT.MEDIUM};
  text-align: left;

  color: ${COLOR.WHITE};
`

const Summary = styled('p')`
  transition: transform 0.2s ease-out;

  ${({ $isOpen }) =>
    $isOpen &&
    `
      transform: translateX(${SPACE.S});
    `}
`

const Content = styled(DisclosurePanel)`
  margin: ${SPACE.M} ${SPACE.S} ${SPACE.M} ${SPACE.XL};
  padding-left: ${SPACE.M};

  border-left: 2px solid ${COLOR.PRIMARY_RED};

  &[data-state='open'] {
    animation: ${reveal} 0.2s ease-out;
  }
`

const Sign = styled('span')`
  position: relative;
  top: 2px;

  margin-right: ${SPACE.M};
  min-width: 1em;

  font-size: ${FONT_SIZE.XXXL};
  font-weight: ${FONT_WEIGHT.LIGHT};
  line-height: 1;
  text-align: center;

  color: ${COLOR.PRIMARY_RED};
  background-color: ${COLOR.PRIMARY_RED_90};
  border-radius: ${RADIUS.CIRCLE};
`

const Disclosure = ({ summary, content, isOpen, onChange }) => (
  <Wrapper open={isOpen} onChange={onChange}>
    <div>
      <Button>
        <Sign>{isOpen ? '–' : '+'}</Sign>
        <Summary $isOpen={isOpen}>{summary}</Summary>
      </Button>
      <Content>{content}</Content>
    </div>
  </Wrapper>
)

Disclosure.propTypes = {
  summary: PropTypes.node,
  content: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Disclosure
