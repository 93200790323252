import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { cover } from 'polished'
import { layout } from 'styled-system'
import { COLOR, GRADIENT, mq, SPACE } from 'Theme'
import { Text } from 'components/Typography'

const StyledForm = styled('form')`
  position: relative;

  height: 100%;

  border: 1px solid ${COLOR.DARK_GRAY};
  background-color: ${COLOR.BLACK};
  background-image: linear-gradient(${GRADIENT.COURSE_CARD});

  ${layout}
`

const PaddingWrapper = styled('div')`
  padding: ${SPACE.M};

  ${mq.from.M`
    padding: ${SPACE.M} ${SPACE.L};
  `}
`

const Header = styled(PaddingWrapper)`
  border-bottom: 1px solid ${COLOR.SQUANT};
`

const RibbonLayer = styled('div')`
  pointer-events: none;
  overflow: hidden;

  ${cover()}
`

const Form = ({ children, onSubmit, ribbonComp, title, ...props }) => (
  <StyledForm onSubmit={onSubmit} {...props}>
    <Header>
      <Text fontSize="22px">{title}</Text>
      {ribbonComp && <RibbonLayer>{ribbonComp}</RibbonLayer>}
    </Header>
    <PaddingWrapper>{children}</PaddingWrapper>
  </StyledForm>
)

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  ribbonComp: PropTypes.node,
  title: PropTypes.string.isRequired,
}

export default Form
