import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import { COLOR, SPACE, FONT_SIZE, mq } from 'Theme'
import { Link } from 'gatsby'
import { DOCUMENT_URL, FORM_NAME } from 'constants/constants'
import Button from 'components/Button'
import Form from 'components/Form'
import Modal, { useModal } from 'components/Modal'
import TextInput from 'components/TextInput'
import { Text } from 'components/Typography'
import { postForm } from 'utils/netlify-form'
import useMessage from 'utils/useMessage'
import Checkbox from 'components/Checkbox'
import { Flex, Grid } from 'components/Layout'
import SuccessMessage from '../course/SuccessMessage'

const EMAIL_REGEX = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/

const COURSE_TYPE = {
  JAVASCRIPT: 'JavaScript',
  PRODUCT_MANAGEMENT: 'ProductManager',
}

const { NAME, EMAIL, TYPE, AGREEMENT } = {
  NAME: 'name',
  EMAIL: 'email',
  TYPE: 'type',
  AGREEMENT: 'agreement',
}

const INITIAL_INPUTS_STATE = {
  [NAME]: undefined,
  [EMAIL]: undefined,
  [AGREEMENT]: false,
}

const FormWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  padding: ${SPACE.M} 0;

  > * {
    flex: 1;
  }
`

const MarginWrapper = styled('div')`
  > * + * {
    margin-top: ${SPACE.L};
  }
`

const SyllabusImage = styled('img')`
  position: relative;
  right: -${SPACE.L};
  bottom: -${SPACE.L};

  margin-top: -${SPACE.XL};
  max-width: ${rem(420)};

  ${mq.to.M`
    display: none;
  `}
`

const LinkWithoutUnderLine = styled(Link)`
  text-decoration: none;
`

const DownloadSyllabusButton = styled(Button)`
  width: 100%;
  margin: ${SPACE.L} 0;
`

const Description = styled('p')`
  font-size: ${FONT_SIZE.S};
  margin-top: ${SPACE.M};
`

const SyllabusModal = ({
  courseType,
  syllabusImage,
  syllabusUrl,
  ...props
}) => {
  const { setErrorMessage } = useMessage()
  const [inputValues, setInputValues] = React.useState({
    ...INITIAL_INPUTS_STATE,
    type: courseType,
  })
  const [
    isSuccessMessageDisplayed,
    setIsSuccessMessageDisplayed,
  ] = React.useState(false)

  const handleInputChange = ({ target: { name, value, checked } }) => {
    setInputValues((prev) => ({
      ...prev,
      [name]: name === AGREEMENT ? checked : value,
    }))
  }

  const validateInput = (value, name) => {
    if (name === EMAIL) {
      return value === INITIAL_INPUTS_STATE[EMAIL]
        ? true
        : EMAIL_REGEX.test(value)
    }
    return value === INITIAL_INPUTS_STATE[NAME]
      ? true
      : value.length > 0 && value !== ''
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      if (
        !inputValues[NAME] ||
        !inputValues[EMAIL] ||
        !inputValues[AGREEMENT] ||
        !validateInput(inputValues[EMAIL], EMAIL)
      ) {
        return
      }
      await postForm({
        body: inputValues,
        formName: FORM_NAME.SYLLABUS,
      })

      if (window.dataLayer != null) {
        window.dataLayer.push({ event: 'syllabus' })
      }

      setIsSuccessMessageDisplayed(true)
    } catch (error) {
      setErrorMessage('Niekde nastala chyba, skúste to neskôr, prosím')
    }
  }

  return (
    <Modal
      {...props}
      ariaLabel="Syllabus modal"
      width={{ S: '94vw', M: `min(100%, ${rem(400)})` }}
      isTransparentMobile
    >
      <Form onSubmit={handleSubmit} title="Získaj Sylabus">
        <FormWrapper>
          <MarginWrapper>
            <Description>Po odoslaní ti zašleme sylabus kurzu</Description>
            <TextInput
              label="MENO A PRIEZVISKO"
              placeholder="Tvoje meno a priezvisko"
              name={NAME}
              value={inputValues[NAME]}
              onChange={handleInputChange}
              isValid={validateInput(inputValues[NAME], NAME)}
              required
            />
            <TextInput
              type="email"
              label="EMAIL"
              placeholder="Tvoj email"
              name={EMAIL}
              value={inputValues[EMAIL]}
              onChange={handleInputChange}
              isValid={validateInput(inputValues[EMAIL], EMAIL)}
              required
            />
            <input type="hidden" name={TYPE} value={courseType} />
            <div>
              <Button
                id={`syllabus-modal-submit-${courseType.toLowerCase()}`}
                type="submit"
                isFullWidth
                mt={SPACE.M}
              >
                Získaj sylabus kurzu
              </Button>
              <Grid
                alignItems="center"
                gridTemplateColumns="1fr 9fr"
                mt={SPACE.XM}
                mb={SPACE.S}
              >
                <Checkbox
                  name={AGREEMENT}
                  isChecked={inputValues[AGREEMENT]}
                  handleChange={handleInputChange}
                  required
                />
                <Text as="p" fontSize={rem(14)} mr={SPACE.L}>
                  Súhlasím s{' '}
                  <Text
                    id={`application-form-privacy-policy-${courseType.toLowerCase()}`}
                    as="a"
                    target="_blank"
                    href={DOCUMENT_URL.TRADING_CONDITIONS}
                    color={COLOR.PRIMARY_RED}
                  >
                    obchodnými podmienkami
                  </Text>{' '}
                  a zásadami{' '}
                  <Text
                    id={`application-form-privacy-policy-${courseType.toLowerCase()}`}
                    as="a"
                    target="_blank"
                    href={DOCUMENT_URL.PRIVACY_POLICY}
                    color={COLOR.PRIMARY_RED}
                  >
                    ochrany osobných údajov
                  </Text>
                </Text>
              </Grid>
            </div>
          </MarginWrapper>
          {syllabusImage && <SyllabusImage src={syllabusImage} alt="" />}
        </FormWrapper>
        <SuccessMessage
          $isVisible={isSuccessMessageDisplayed}
          text={
            <>
              <LinkWithoutUnderLine target="_blank" href={syllabusUrl}>
                <DownloadSyllabusButton>Stiahni Sylabus</DownloadSyllabusButton>
              </LinkWithoutUnderLine>
              <Flex justifyContent="center">
                <Text as="p" width={{ S: '100%', M: '80%' }}>
                  Kópiu dokumentu sme ti zaslali taktiež na tvoj email.
                </Text>
              </Flex>
            </>
          }
        />
      </Form>
    </Modal>
  )
}

SyllabusModal.propTypes = {
  courseType: PropTypes.oneOf(Object.values(COURSE_TYPE)),
  syllabusImage: PropTypes.string,
  syllabusUrl: PropTypes.string.isRequired,
}

export { SyllabusModal as default, useModal as useSyllabusModal }
