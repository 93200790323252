import React from 'react'
import PropTypes from 'prop-types'
import { scrollIntoView } from 'utils/scroll'
import { handleize } from 'utils/helpers'
import Button from 'components/Button'

const SCROLL_OFFSET = 20

const scrollToApply = (sectionName) => {
  scrollIntoView({
    name: handleize(sectionName),
    offset: -SCROLL_OFFSET,
  })
}

const ApplyButton = ({ sectionName, ...props }) => (
  <Button
    onClick={() => {
      scrollToApply(sectionName)
    }}
    {...props}
  >
    Prihlás sa
  </Button>
)

ApplyButton.propTypes = {
  sectionName: PropTypes.string.isRequired,
}

export { ApplyButton as default, scrollToApply }
