import styled from 'styled-components'
import Link from 'components/Link'
import { Link as GatsbyLink } from 'gatsby'

export const UnderLineLink = styled(Link)`
  text-decoration: underline;
`

export const UnderLineGatsbyLink = styled(GatsbyLink)`
  text-decoration: underline;
  color: inherit;
`
