import styled from 'styled-components'
import { rem } from 'polished'
import { fullWidthStyle } from 'components/mixins'
import { SPACE, mq } from 'Theme'

export default styled('div')`
  ${fullWidthStyle}

  min-height: ${rem(340)};
  height: 33.333vw;

  ${mq.from.M`
    margin-top: ${SPACE.XL};
  `}

  ${({ src }) => `background: url("${src}") center / cover no-repeat;`}
`
