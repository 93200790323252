import React from 'react'
import Button from 'components/Button'
import PropTypes from 'prop-types'
import downloadIcon from 'images/svg/save.svg'

const SyllabusButton = ({
  hasDownloadIcon,
  label = 'Získaj sylabus',
  variant = 'supplementary',
  ...props
}) => (
  <Button variant={variant} {...props}>
    {hasDownloadIcon && <img src={downloadIcon} alt="Stiahnuť" />}
    {label}
  </Button>
)

SyllabusButton.propTypes = {
  hasDownloadIcon: PropTypes.bool,
  label: PropTypes.string,
  variant: PropTypes.string,
}

export default SyllabusButton
