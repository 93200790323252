import styled from 'styled-components'
import { COLOR } from 'Theme'
import { Flex } from './Layout'

const Line = styled(Flex)`
  width: 100%;
  height: 1px;
  background-color: ${COLOR.WHITE_50};
`

export default Line
