import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box } from 'components/Layout'
import { COLOR, FONT_SIZE, SPACE } from 'Theme'

const StyledBox = styled(Box)`
  border: 1px solid ${COLOR.WHITE_75};
`

const Title = styled('p')`
  font-size: ${FONT_SIZE.S};
  text-transform: uppercase;
`

const Price = styled('p')`
  font-size: ${FONT_SIZE.XXXXL};
`

const Description = styled('p')`
  font-size: ${FONT_SIZE.M};
`

const PriceInfo = ({ title, price, description }) => (
  <StyledBox px={{ S: SPACE.M, M: SPACE.XM }} pt={SPACE.XM} pb={SPACE.M}>
    <Title>{title}</Title>
    <Price>{price}</Price>
    <Description>{description}</Description>
  </StyledBox>
)

PriceInfo.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default PriceInfo
