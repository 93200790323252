import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SPACE, COLOR, mq } from 'Theme'
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'

SwiperCore.use([Pagination, Autoplay])

const IsolatedDiv = styled('div')`
  isolation: isolate;
  ${mq.from.M`
    display: none;
  `}
`

const SwiperWrapper = styled('div')`
  padding: ${SPACE.XM} 0 ${SPACE.XL};

  .swiper-slide {
    height: auto;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 0 6px !important;
    background: ${COLOR.SQUANT};
    &-active {
      background: ${COLOR.PRIMARY_RED};
    }
  }
`

const Carousel = ({ slides }) => (
  <IsolatedDiv>
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 5000,
      }}
      tag={SwiperWrapper}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>{slide}</SwiperSlide>
      ))}
    </Swiper>
  </IsolatedDiv>
)

Carousel.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.node).isRequired,
}

export default Carousel
