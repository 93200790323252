import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { openPopupWidget } from 'react-calendly'
import { COLOR, FONT_SIZE, mq, SPACE } from 'Theme'
import Button from 'components/Button'
import { Grid } from 'components/Layout'
import CameraIcon from 'images/svg/camera.inline.svg'
import PhoneIcon from 'images/svg/phone.svg'
import Link from 'components/Link'

const StyledGrid = styled(Grid)`
  border: 1px solid ${COLOR.WHITE_75};
  text-align: center;
`

const Description = styled('p')`
  font-size: ${FONT_SIZE.M};
  line-height: ${SPACE.XM};

  ${mq.from.M`
    display: flex;
    align-items: center;
    text-align: left;
    line-height: ${SPACE.L};
  `}
`

const StyledButton = styled(Button)`
  width: 100%;
  max-width: ${SPACE.XXXXL};

  ${mq.from.M`
    width: unset;
  `}
`

const SubButtonContent = styled('p')`
  font-size: ${FONT_SIZE.XS};
  color: ${COLOR.LIGHT_GRAY};
`

const SubButtonEmail = styled(Link)`
  text-decoration: underline;
`

const CTASchedule = ({
  description,
  buttonText,
  subButtonText,
  subButtonEmail,
}) => (
  <StyledGrid
    backgroundImage={{ S: 'none', M: `url(${PhoneIcon})` }}
    backgroundRepeat="no-repeat"
    backgroundSize="6.5rem"
    backgroundPosition={`${SPACE.S} center`}
    px={{ S: SPACE.M, M: SPACE.XM }}
    py={{ S: SPACE.XM, M: SPACE.L }}
    mt={SPACE.XL}
    gridTemplateColumns={{ S: '1fr', M: '1fr auto' }}
    gridColumnGap={SPACE.XL}
  >
    <Description>{description}</Description>
    <div>
      <StyledButton
        variant="supplementary"
        my={SPACE.M}
        mt={{ M: '0' }}
        onClick={() =>
          openPopupWidget({
            url: 'https://calendly.com/sudoacademy/30min',
          })
        }
      >
        <CameraIcon />
        {buttonText}
      </StyledButton>
      <SubButtonContent>
        {subButtonText}
        <SubButtonEmail href={`mailto: ${subButtonEmail}`} target="_blank">
          {subButtonEmail}
        </SubButtonEmail>
      </SubButtonContent>
    </div>
  </StyledGrid>
)

CTASchedule.propTypes = {
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  subButtonText: PropTypes.string.isRequired,
  subButtonEmail: PropTypes.string.isRequired,
}

export default CTASchedule
