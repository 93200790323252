import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import tick from 'images/svg/checkbox-tick.svg'

const CHECKBOX_SIZE = rem(20)

const StyledLabel = styled('label')`
  display: block;
  width: ${CHECKBOX_SIZE};
  height: ${CHECKBOX_SIZE};
  position: relative;
  cursor: pointer;
  user-select: none;
`

const StyledCheckbox = styled('input')`
  opacity: 0;
  height: ${CHECKBOX_SIZE};
  width: ${CHECKBOX_SIZE};
`

const Checkmark = styled('span')`
  position: absolute;
  top: 0;
  left: 0;
  height: ${CHECKBOX_SIZE};
  width: ${CHECKBOX_SIZE};
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${rem(15)};
  background-image: ${({ isChecked }) => (isChecked ? `url(${tick})` : 'none')};
  background-color: black;
  border: 1px solid #c4c4c4;

  &:after {
    content: '';
    position: absolute;
    display: ${({ isChecked }) => (isChecked ? `block` : `none`)};
  }
`

const Checkbox = ({ name, isChecked, handleChange, ...props }) => (
  <StyledLabel>
    <StyledCheckbox
      type="checkbox"
      name={name}
      checked={isChecked}
      onChange={handleChange}
      {...props}
    />
    <Checkmark isChecked={isChecked} />
  </StyledLabel>
)

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  handleChange: PropTypes.func,
}

export default Checkbox
