import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import { COLOR, FONT_WEIGHT, RADIUS, SPACE, mq } from 'Theme'
import { Text } from 'components/Typography'
import tickIcon from 'images/svg/tick.svg'
import { Grid } from './Layout'

const StyledCheckList = styled(Grid)`
  grid-gap: ${SPACE.L};

  ${mq.from.M`
    grid-template-columns: 1fr 1fr;
  `}
`

const Item = styled('div')`
  display: flex;
  font-weight: ${FONT_WEIGHT.MEDIUM};
`

const Tick = styled('span')`
  flex: none;
  height: ${rem(24)};
  width: ${rem(24)};
  margin-right: ${SPACE.M};

  background: url("${tickIcon}") center / 55% no-repeat;
  background-color: ${COLOR.PRIMARY_RED_90};
  border-radius: ${RADIUS.CIRCLE};
`

const CheckList = ({ items }) => (
  <StyledCheckList>
    {items.map(({ text }, index) => (
      <Item key={index}>
        <Tick />
        <div>
          <p>{text}</p>
        </div>
      </Item>
    ))}
  </StyledCheckList>
)

CheckList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      extra: PropTypes.string,
    }).isRequired
  ).isRequired,
}

export default CheckList
