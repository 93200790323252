import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal, { useModal } from 'components/Modal'
import { COLOR } from 'Theme'
import { rem } from 'polished'
import ReactPlayer from 'react-player/youtube'

const VideoWrapper = styled('div')`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
`

const Player = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`

const VideoModal = ({ videoUrl, ...props }) => (
  <Modal
    {...props}
    ariaLabel="Video modal"
    width={`min(100%, ${rem(800)})`}
    height="max-content"
    btnBgColor={COLOR.BLACK}
    isTransparentMobile
  >
    <VideoWrapper>
      <Player url={videoUrl} controls playing width="100%" height="100%" />
    </VideoWrapper>
  </Modal>
)

VideoModal.propTypes = {
  videoUrl: PropTypes.string.isRequired,
}

export { VideoModal as default, useModal as useVideoModal }
