import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FONT_SIZE, SPACE, COLOR, RADIUS } from 'Theme'
import { Text } from 'components/Typography'
import { Flex } from 'components/Layout'
import TickIcon from 'images/svg/tick.inline.svg'

const BUTTON_SIZE = 110

const Circle = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: ${BUTTON_SIZE}px;
  height: ${BUTTON_SIZE}px;
  margin-bottom: ${SPACE.L};
  border-radius: ${RADIUS.CIRCLE};
  border: 1px solid ${COLOR.WHITE_25};
`

const SuccessMessage = ({ text, title, ...props }) => (
  <Flex
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    padding={SPACE.L}
    {...props}
  >
    <Circle>
      <TickIcon />
    </Circle>
    {title && (
      <Text as="div" textAlign="center" fontSize={FONT_SIZE.XXL} pb={SPACE.XM}>
        {title}
      </Text>
    )}
    {text && (
      <Text as="div" textAlign="center" fontSize={FONT_SIZE.M}>
        {text}
      </Text>
    )}
  </Flex>
)

SuccessMessage.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
}

export default SuccessMessage
