import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space } from 'styled-system'
import { rem } from 'polished'
import { Link } from 'react-scroll'
import { COLOR, FONT_SIZE, FONT_WEIGHT, mq, RADIUS, SPACE } from 'Theme'
import { Text } from 'components/Typography'
import { handleize } from 'utils/helpers'

const MENU_ITEM_HEIGHT = rem(50)
const SCROLL_OFFSET = -20

const Dot = styled('span')`
  isolation: isolate;
  display: block;

  flex: none;
  width: 9px;
  height: 9px;
  margin-right: ${SPACE.XM};

  background-color: ${COLOR.ARGENT};
  border-radius: ${RADIUS.CIRCLE};
  border: 2px solid transparent;

  transition: border-color, background-color;
  transition-duration: 0.4s;
`

const Line = styled('span')`
  position: relative;
  left: 5px;

  flex: none;
  height: ${MENU_ITEM_HEIGHT};

  border-left: 1px solid ${COLOR.ARGENT};

  transform: translateY(50%);
  opacity: 0.5;
`

const Label = styled(Text)`
  opacity: 0.5;

  transition: opacity 0.4s;

  &:hover {
    opacity: 0.75;

    transition: none;
  }
`

const StyledItem = styled(Link)`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  height: ${MENU_ITEM_HEIGHT};

  &.active ${Label} {
    opacity: 1;

    transition: none;
  }

  &.active ${Dot} {
    background-color: ${COLOR.SOOTY};
    border-color: ${COLOR.PRIMARY_RED};

    transition: none;
  }

  &:last-child ${Line} {
    display: none;
  }
`

const Item = ({ title }) => (
  <StyledItem
    id={`floating-menu-${handleize(title)}`}
    to={handleize(title)}
    activeClass="active"
    spy
    duration={800}
    delay={0}
    offset={SCROLL_OFFSET}
    smooth="easeInOutQuart"
  >
    <Line />
    <Dot />
    <Label fontSize={FONT_SIZE.L} fontWeight={FONT_WEIGHT.MEDIUM}>
      {title}
    </Label>
  </StyledItem>
)

Item.propTypes = {
  title: PropTypes.string.isRequired,
}

const Menu = styled('div')`
  position: sticky;
  top: calc(${SCROLL_OFFSET} * -1px);
  z-index: 1;

  flex: none;
  width: ${rem(288)};
  padding: ${SPACE.XM};
  margin-right: ${SPACE.XL};

  line-height: 1.2;

  background-color: ${COLOR.SOOTY};

  ${space}

  ${mq.to.L`
    display: none;
  `}
`

const FloatingMenu = ({ children, ...props }) => (
  <Menu {...props}>{children}</Menu>
)

FloatingMenu.propTypes = {
  children: PropTypes.node.isRequired,
}

FloatingMenu.Item = Item

export default FloatingMenu
