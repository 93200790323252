import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { layout } from 'styled-system'
import { rem, transparentize } from 'polished'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { COLOR, mq } from 'Theme'
import xMarkIcon from 'images/svg/xmark.svg'

const Overlay = styled(DialogOverlay)`
  overflow: auto;
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: grid;
  justify-items: center;
  align-content: center;

  background-color: ${transparentize(0.2, COLOR.BLACK)} !important;
`

const Content = styled(DialogContent)`
  overflow: hidden;
  position: relative;

  max-height: 100%;
  max-width: ${rem(800)};

  background-color: ${COLOR.BLACK};
  outline: none;

  ${mq.to.M`
    max-width: none;
    min-width: 0;
    width: 100vw;
    height: 
    ${({ isTransparentMobile }) =>
      isTransparentMobile ? 'auto' : 'var(--sa-100vh, 100vh)'};
  `}

  ${layout}
`

const CloseButton = styled('button')`
  position: absolute;
  top: ${rem(11)};
  right: ${rem(25)};

  width: ${rem(42)};
  height: ${rem(42)};

  background-color: ${({ bgColor }) => bgColor || 'transparent'};
`

const Modal = ({
  children,
  isOpen,
  closeModal,
  ariaLabel,
  btnBgColor,
  ...props
}) => (
  <div>
    <Overlay isOpen={isOpen} onDismiss={closeModal}>
      <Content aria-label={ariaLabel} {...props}>
        {children}
        <CloseButton type="button" onClick={closeModal} bgColor={btnBgColor}>
          <img src={xMarkIcon} alt="Close button" />
        </CloseButton>
      </Content>
    </Overlay>
  </div>
)

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  btnBgColor: PropTypes.string,
  isTransparentMobile: PropTypes.bool,
}

export const useModal = () => {
  const [isModalOpen, setModalOpen] = React.useState(false)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const toggleModal = () => {
    setModalOpen((prevIsModalOpen) => !prevIsModalOpen)
  }

  return { isModalOpen, openModal, closeModal, toggleModal }
}

export default Modal
