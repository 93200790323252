import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import { Element } from 'react-scroll'
import { COLOR, FONT_SIZE, FONT_WEIGHT, SPACE, mq } from 'Theme'
import FloatingMenu from 'components/FloatingMenu'
import { BUTTON_HEIGHT } from 'components/Button'
import { Box } from 'components/Layout'
import { handleize } from 'utils/helpers'

const MAX_CONTENT_WIDTH = rem(848)

const StyledSection = styled(Element)`
  padding: ${SPACE.XL} 0;

  line-height: 1.65;

  ${({ $hasTopBorder }) =>
    $hasTopBorder &&
    `
      & + & {
        border-top: 1px solid ${COLOR.WHITE_50};
      }
    `}
`

const TitleIcon = styled('img')`
  display: block;

  width: ${rem(40)};
  margin-bottom: ${SPACE.S};
`

const StyledTitle = styled('h2')`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: ${BUTTON_HEIGHT};
  margin-bottom: ${SPACE.L};

  font-size: ${FONT_SIZE.XXXXL};
  font-weight: ${FONT_WEIGHT.NORMAL};

  > * {
    margin-left: ${SPACE.M};
  }

  ${mq.from.M`
    justify-content: space-between;
  `}
`

const Title = ({ children, icon }) => (
  <>
    {icon && <TitleIcon src={icon} alt="" />}
    <StyledTitle>{children}</StyledTitle>
  </>
)

Title.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
}

const Section = ({
  children,
  isTitleHidden,
  title,
  titleContent,
  hasTopBorder = true,
}) => (
  <StyledSection name={handleize(title)} $hasTopBorder={hasTopBorder}>
    {!isTitleHidden && (
      <Title>
        {title} {titleContent}
      </Title>
    )}
    <div>{children}</div>
  </StyledSection>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
  isTitleHidden: PropTypes.bool,
  title: PropTypes.string,
  titleContent: PropTypes.node,
  hasTopBorder: PropTypes.bool,
}

const StyledContent = styled('div')`
  font-size: ${FONT_SIZE.L};
  line-height: ${rem(30)};

  ${mq.from.M`
    display: flex;
    align-items: flex-start;
  `}
`

const Content = ({ children, menuButton, menuContent }) => (
  <StyledContent>
    <FloatingMenu mt={SPACE.XL}>
      <div>
        {menuContent.map((title, index) => (
          <FloatingMenu.Item title={title} key={title} isFirst={index === 0} />
        ))}
      </div>
      {menuButton}
    </FloatingMenu>
    <Box maxWidth={{ L: MAX_CONTENT_WIDTH }}>{children}</Box>
  </StyledContent>
)

Content.propTypes = {
  children: PropTypes.node.isRequired,
  menuButton: PropTypes.node,
  menuContent: PropTypes.arrayOf(PropTypes.string),
}

Content.Section = Section
Content.Title = Title

export default Content
