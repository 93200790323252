import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import { FONT_SIZE, FONT_WEIGHT, RADIUS, SPACE, COLOR, mq } from 'Theme'
import { Text } from 'components/Typography'
import { Flex } from 'components/Layout'
import Linkedin from 'images/svg/linkedin.inline.svg'
import Carousel from 'components/Carousel'

const Grid = styled('div')`
  display: none;
  grid-template-columns: repeat(${({ cols }) => cols || 2}, 1fr);
  grid-gap: ${({ cols }) => (cols > 2 ? SPACE.M : SPACE.L)};

  ${mq.from.M`
    display: grid;
  `}
`

const StyledReviewCard = styled(Flex)`
  position: relative;
  background-color: ${COLOR.BLACK_CARD};
  border-radius: ${RADIUS.L};
  height: 100%;
`

const OverflowWrapper = styled('div')`
  overflow: hidden;
  padding: ${SPACE.L};
  width: 100%;
  flex-grow: 1;

  ${mq.from.M`
    padding: ${SPACE.XM} ${SPACE.M} ${SPACE.M} ${SPACE.M};
  `}
`

const Badge = styled('div')`
  position: absolute;
  top: -1.3em;
  right: ${SPACE.M};

  background-color: ${COLOR.PRIMARY_RED};
  border-radius: ${RADIUS.M};
  padding: ${rem(6)} ${rem(10)};
  color: ${COLOR.BLACK};
  font-size: ${FONT_SIZE.S};
  font-weight: ${FONT_WEIGHT.MEDIUM};
`

const Review = styled(Text)`
  ${({ hasQuoteBg }) =>
    hasQuoteBg &&
    `
      position: relative;
      &::after {
        content: '“';
        pointer-events: none;
        user-select: none;

        position: absolute;
        top: 0.05em;
        left: -0.02em;

        font-family: Telegraf;
        font-size: ${rem(600)};
        font-style: normal;
        line-height: 0.65;
        color: ${COLOR.SQUANT};

        opacity: 0.2;

        ${mq.from.M`
          top: 0.07em;
        `}
      }
  `}
`

const Photo = styled('img')`
  width: ${rem(43)};
  height: ${rem(43)};
  margin-right: ${SPACE.S};
  border-radius: ${RADIUS.CIRCLE};
`

const LinkedInWrapper = styled('div')`
  flex-grow: 1;
  text-align: right;
`

const ReviewCard = ({
  image,
  name,
  position,
  text,
  isQuoted,
  badge,
  linkedIn,
}) => (
  <StyledReviewCard flexDirection="column" alignItems="flex-start">
    <OverflowWrapper>
      {badge && <Badge>{badge}</Badge>}
      <Flex alignItems="center" mb={SPACE.M} width="100%">
        <Photo src={image} alt={`Fotka ${name}`} />
        <div>
          <Text as="p" fontSize={FONT_SIZE.M} fontWeight={FONT_WEIGHT.MEDIUM}>
            {name}
          </Text>
          <Text as="p" fontSize={FONT_SIZE.S} opacity="0.5" mt={`-${SPACE.XS}`}>
            {position}
          </Text>
        </div>
        {linkedIn && (
          <LinkedInWrapper>
            <a href={linkedIn} target="_blank" rel="noopener noreferrer">
              <Linkedin />
            </a>
          </LinkedInWrapper>
        )}
      </Flex>
      <Review
        as="p"
        fontSize={{ S: FONT_SIZE.M, M: FONT_SIZE.XM }}
        fontWeight={FONT_WEIGHT.MEDIUM}
        fontStyle={isQuoted ? 'italic' : 'none'}
        lineHeight={1.75}
        hasQuoteBg={isQuoted}
      >
        {isQuoted ? `"${text}"` : text}
      </Review>
    </OverflowWrapper>
  </StyledReviewCard>
)

const Reviews = ({ items, cols }) => {
  const cards = items.map((props, index) => (
    <ReviewCard {...props} key={index} />
  ))

  return (
    <>
      <Carousel slides={cards} />
      <Grid cols={cols}>{cards}</Grid>
    </>
  )
}

Reviews.propTypes = {
  cols: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      isQuoted: PropTypes.bool,
      badge: PropTypes.string,
      linkedIn: PropTypes.string,
    }).isRequired
  ).isRequired,
}

ReviewCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isQuoted: PropTypes.bool,
  badge: PropTypes.string,
  linkedIn: PropTypes.string,
}

export default Reviews
