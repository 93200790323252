import React from 'react'
import styled from 'styled-components'
import PlayButtonSvg from 'images/svg/playbutton.svg'
import PropTypes from 'prop-types'
import { mq, SPACE } from 'Theme'
import VideoModal, { useVideoModal } from './VideoModal'

const BUTTON_SIZE_MEDIUM = 70
const BUTTON_SIZE_LARGE = 100

const Window = styled('div')`
  width: 100%;
  position: relative;
`

const Thumbnail = styled('img')`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const PlayButton = styled('button')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: ${SPACE.XL};
  width: ${SPACE.XL};

  ${mq.from.M`
    height: ${BUTTON_SIZE_MEDIUM}px;
    width: ${BUTTON_SIZE_MEDIUM}px;
  `}

  ${mq.from.L`
    height: ${BUTTON_SIZE_LARGE}px;
    width: ${BUTTON_SIZE_LARGE}px;
  `}
`

const PlayButtonIcon = styled('img')`
  height: 100%;
`

const VideoPlayer = ({ thumbnailUrl, videoUrl, ...props }) => {
  const { isModalOpen, openModal, closeModal } = useVideoModal()

  return (
    <>
      <Window {...props}>
        <Thumbnail src={thumbnailUrl} />
        <PlayButton onClick={openModal}>
          <PlayButtonIcon src={PlayButtonSvg} alt="Play Button" />
        </PlayButton>
      </Window>
      <VideoModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        videoUrl={videoUrl}
      />
    </>
  )
}

VideoPlayer.propTypes = {
  thumbnailUrl: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
}

export default VideoPlayer
